<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="类型" prop="type" :rules="rules">
        <SelectDict width="400px" v-model="formData.type" dictCode="system_help_center_type"></SelectDict>
      </el-form-item>
      <el-form-item label="标题" prop="title" :rules="rules">
        <el-input style="width: 400px" v-model="formData.title" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="内容">
        <el-input style="width: 400px" type="textarea" rows="4" v-model="formData.content" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-switch v-model="formData.status" active-text="展示" inactive-text="不展示" :active-value="1" :inactive-value="0"> </el-switch>
      </el-form-item>
      <el-form-item label="排序">
        <el-input style="width: 400px" v-model="formData.sort" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getDetailAPI, addAPI, editAPI } from './api'
import SelectDict from '@/views/components/select-dict.vue'
export default {
  name: 'AddOrEdit',
  components: { SelectDict },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        type: '',
        title: '',
        content: '',
        status: '',
        sort: 0
      }
    }
  },

  mounted() {
    if (this.$route.params.id) this.getDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail() {
      this.formData = await getDetailAPI(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped></style>
